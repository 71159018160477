import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { getCalendarDateFromTimestamp, PARIS, type CalendarDate } from '@orus.eu/calendar-date'
import type {
  LegalRepresentative,
  PersonaLessInsuranceCertificateRealEstateAgentsData,
} from '@orus.eu/documents/src/documents/insurance-certificate/insurance-certificate-props'
import {
  Button,
  Checkbox,
  ContentContainerAppClient,
  DatepickerFormField,
  FlexColumn,
  Form,
  FormRow,
  PageTitle,
  RowButtonV2,
  Select,
  spacing,
  Text,
  TextFieldFormField,
  TextInputLabelWrapper,
  useAsyncCallback,
  useDownloadFile,
  useLanguage,
  ValidatedTextFieldFormField,
  type ValidatedTypeMapper,
} from '@orus.eu/pharaoh'
import { RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { failure, success } from '@orus.eu/result'
import { useParams } from '@tanstack/react-router'
import { memo, useMemo, useState, type ReactElement } from 'react'
import { dynamicFileNames } from '../../lib/download-filenames'
import { getMySpecificCertificateRealEstateAgentsDownloadLink } from '../../lib/download-links'
import { sendMessage } from '../../lib/tracking/tracking'

const cpiNumberMapper: ValidatedTypeMapper<string> = {
  inputType: 'number',
  format: (value) => value.trim(),
  formatPlaceholder: (value) => value.trim(),
  parse: (number) => {
    return number.replace(' ', '').length === 17 ? success(number) : failure('Numéro de CPI invalide')
  },
}

const activitiesRealEstateAgents = ['transactionImmo', 'gestionImmo', 'syndicCopro'] as const
type ActivitiesRealEstateAgents = (typeof activitiesRealEstateAgents)[number]

const labelActivities: Record<ActivitiesRealEstateAgents, string> = {
  transactionImmo: 'Transaction immobilière',
  gestionImmo: 'Gestion immobilière',
  syndicCopro: 'Syndic de copropriété',
}

const InsuranceCertificateGeneratorRealEstateAgentsPage = memo(
  function InsuranceCertificateGeneratorRealEstateAgentsPage(): ReactElement {
    const { subscriptionId } = useParams({
      from: '/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId',
    })
    const language = useLanguage()

    const [companyName, setCompanyName] = useState('')
    const [cpiNumber, setCpiNumber] = useState<string | undefined>(undefined)
    const [cciName, setCciName] = useState('')
    const [dateOfIssue, setDateOfIssue] = useState<CalendarDate | null>(null)
    const [dateOfValidity, setDateOfValidity] = useState<CalendarDate | null>(null)

    const [legalRepresentatives, setLegalRepresentatives] = useState<LegalRepresentative[]>([])

    const [selectedActivities, setSelectedActivities] = useState<Record<ActivitiesRealEstateAgents, boolean>>({
      transactionImmo: false,
      gestionImmo: false,
      syndicCopro: false,
    })

    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)

    const currentDate = getCalendarDateFromTimestamp(Date.now(), PARIS)

    const fields: PersonaLessInsuranceCertificateRealEstateAgentsData = useMemo(
      () => ({
        companyName,
        cpiNumber: cpiNumber ?? '',
        cciName,
        dateOfIssue,
        dateOfValidity,
        legalRepresentatives: legalRepresentatives.filter(({ name }) => name),
        hasTransactionImmo: selectedActivities.transactionImmo,
        hasGestionImmo: selectedActivities.gestionImmo,
        hasSyndicCopro: selectedActivities.syndicCopro,
      }),
      [
        companyName,
        cpiNumber,
        cciName,
        dateOfIssue,
        dateOfValidity,
        legalRepresentatives,
        selectedActivities.transactionImmo,
        selectedActivities.gestionImmo,
        selectedActivities.syndicCopro,
      ],
    )

    const { downloadFile } = useDownloadFile(
      getMySpecificCertificateRealEstateAgentsDownloadLink(subscriptionId, fields),
      dynamicFileNames['specific-insurance-certificate-real-estate-agents'](undefined, language),
    )

    const handleActivityChange = (activity: ActivitiesRealEstateAgents, value: boolean) => {
      setSelectedActivities({ ...selectedActivities, [activity]: value })
    }

    const handleRowClick = (activity: ActivitiesRealEstateAgents) => {
      setSelectedActivities({ ...selectedActivities, [activity]: !selectedActivities[activity] })
    }

    const handleDownload = useAsyncCallback(async () => {
      setDownloadInProgress(true)
      try {
        sendMessage({
          event: 'download_contract_certificate_document',
          document_type: 'specific-insurance-certificate-real-estate-agents',
        })
        await downloadFile()
      } finally {
        setDownloadInProgress(false)
      }
    }, [downloadFile, setDownloadInProgress])

    const isFormValid =
      fields.companyName &&
      fields.cpiNumber &&
      fields.cciName &&
      (fields.dateOfIssue || fields.dateOfValidity) &&
      fields.legalRepresentatives

    return (
      <PaddedContainer>
        <PageTitle title="Nouvelle attestation CCI" />
        <ContentContainerAppClient>
          <Text variant="body2">
            Remplissez le formulaire pour télécharger une attestation CCI.
            <br />
            <br />
            Attestation d’assurance de la RC Professionnelle des agents commerciaux habilités par le titulaire de la
            carte professionnelle exerçant les activités prévues et régies par la loi n° 70-9 du 2 janvier 1970 et du
            décret n°72-678 du 20 juillet 1972.
          </Text>
          <Form>
            <FormRow>
              <TextFieldFormField
                size="large"
                variant="desktop"
                label="Nom de la société"
                placeholder="Société"
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
              />
              <ValidatedTextFieldFormField
                size="large"
                variant="desktop"
                label="Numéro de CPI"
                placeholder="Numéro à 17 chiffres"
                value={cpiNumber}
                onChange={setCpiNumber}
                mapper={cpiNumberMapper}
              />
            </FormRow>

            <FormRow>
              <TextFieldFormField
                size="large"
                variant="desktop"
                infoTooltip="Ex: CCI : Jura"
                label="Nom de la CCI"
                placeholder="Société"
                value={cciName}
                onChange={(event) => setCciName(event.target.value)}
              />
              <DatepickerFormField
                size="large"
                variant="desktop"
                label="Date de délivrance"
                value={dateOfIssue}
                onChange={setDateOfIssue}
                maxDate={currentDate}
              />
              <DatepickerFormField
                size="large"
                variant="desktop"
                label="Date de validité"
                value={dateOfValidity}
                onChange={setDateOfValidity}
              />
            </FormRow>
          </Form>
          <div css={css({ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: spacing[30] })}>
            <Text variant="subtitle">Représentants</Text>
            <Button
              icon="plus-regular"
              size="small"
              variant="secondary"
              onClick={() =>
                setLegalRepresentatives([...legalRepresentatives, { title: 'Monsieur', quality: 'Gérant', name: '' }])
              }
            />
          </div>

          <Form>
            {legalRepresentatives.map((legalRepresentative, index) => (
              <FormRow key={index}>
                <TextInputLabelWrapper label="Civilité">
                  <Select
                    size="large"
                    variant="desktop"
                    labels={{
                      Monsieur: 'Monsieur',
                      Madame: 'Madame',
                    }}
                    value={legalRepresentative.title}
                    values={['Monsieur', 'Madame']}
                    onChange={(event) => {
                      setLegalRepresentatives(
                        legalRepresentatives.map((lr, i) =>
                          i === index ? { ...lr, title: event as 'Monsieur' | 'Madame' } : lr,
                        ),
                      )
                    }}
                  />
                </TextInputLabelWrapper>
                <TextFieldFormField
                  size="large"
                  variant="desktop"
                  label="Représentant légal"
                  value={legalRepresentative.name}
                  placeholder="Nom et prénom"
                  onChange={(event) => {
                    setLegalRepresentatives((previous) =>
                      previous.map((lr, i) => (i === index ? { ...lr, name: event.target.value } : lr)),
                    )
                  }}
                />
                <TextInputLabelWrapper label="Qualité du représentant">
                  <Select
                    size="large"
                    variant="desktop"
                    labels={{
                      Gérant: 'Gérant',
                      Président: 'Président',
                    }}
                    value={legalRepresentative.quality}
                    values={['Gérant', 'Président']}
                    onChange={(event) => {
                      setLegalRepresentatives((previous) =>
                        previous.map((lr, i) =>
                          i === index ? { ...lr, quality: event as 'Gérant' | 'Président' } : lr,
                        ),
                      )
                    }}
                  />
                </TextInputLabelWrapper>{' '}
              </FormRow>
            ))}
          </Form>
          <Form>
            <div
              css={css`
                margin-top: ${spacing[50]};
              `}
            >
              <FormRow>
                <Text
                  css={css`
                    flex-grow: 1;
                  `}
                  variant="h6"
                >
                  Activités de la filiale
                </Text>
              </FormRow>
              <FormRow>
                <RowContainer variant="border" size="medium">
                  {activitiesRealEstateAgents.map((key) => (
                    <RowButtonV2
                      key={key}
                      css={css`
                        padding: ${spacing[50]};
                      `}
                      primaryText={labelActivities[key]}
                      onClick={() => handleRowClick(key)}
                      avatarLeft={
                        <Checkbox
                          size="small"
                          checked={selectedActivities[key]}
                          onChange={(value) => handleActivityChange(key, value)}
                        />
                      }
                    />
                  ))}
                </RowContainer>
              </FormRow>
            </div>
          </Form>

          <div css={css({ display: 'flex', justifyContent: 'flex-end' })}>
            <Button
              icon="arrow-down-to-line-regular"
              size="medium"
              variant="primary"
              avatarPosition="left"
              disabled={!isFormValid}
              onClick={handleDownload}
              isLoading={downloadInProgress}
            >
              Télécharger
            </Button>
          </div>
        </ContentContainerAppClient>
      </PaddedContainer>
    )
  },
)

const PaddedContainer = styled(FlexColumn)`
  padding-top: ${spacing[80]};
  padding-bottom: ${spacing[80]};
`

export default InsuranceCertificateGeneratorRealEstateAgentsPage
